<div
  class="align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8 surface-ground ng-star-inserted"
>
  <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6 max-w-30rem">
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">
        <img src="assets/layout/images/logo.svg" alt="Cybersort" class="logo" />
      </div>
    </div>
    <div>
      <form [formGroup]="registerForm">
        <label class="block text-900 font-medium mb-2">Email</label>
        {{ this.registerForm.value.email }}
        <label class="block text-900 font-medium mb-2" for="password"
          >Password</label
        >
        <input
          autocomplete="new-password"
          class="w-full mb-3"
          data-test="password"
          formControlName="password"
          id="password"
          pInputText
          type="password"
        />
        <button
          (click)="this.changePassword()"
          [disabled]="registerForm.invalid"
          class="w-full"
          data-test="register"
          icon="pi pi-user"
          label="Reset password"
          pButton
          pRipple
        ></button>
        <br /><br />
        <p-message
          *ngIf="errors"
          [escape]="false"
          [text]="errors"
          severity="danger"
          styleClass="error-list"
        ></p-message>
      </form>
    </div>
  </div>
</div>
