<div class="layout-footer">
  <div class="grid gap-1">
    <span class="font-medium ml-2">© Remuscon Oy</span>
    <span>|</span>
    <a class="text-primary-900 underline" href="mailto:support@cybersort.io"
      >Support</a
    >
    <ng-container *ngIf="isSuperAdmin$ | async">
      <span>|</span>
      <a
        class="text-primary-900 underline"
        [href]="apiUrl + '/hangfire?token=' + this.token"
        target="_blank"
        >Hangfire</a
      >
    </ng-container>
  </div>
</div>
