import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

@Injectable()
export class ETagInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const cachedData = this.getCachedResponse(request.url);
    if (cachedData?.etag) {
      request = request.clone({
        headers: request.headers.set('If-None-Match', cachedData.etag)
      });
    }
    return next.handle(request);
  }

  getCachedResponse(url: string): any {
    const cachedData = localStorage.getItem(url);
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    return null;
  }
}
