import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AuthenticateClient } from '../../clients/apiClients';
import { combineLatestWith, map, Observable, Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { TenantService } from '../shared/tenant.service';
import posthog from 'posthog-js';
import { Tenant } from './tenant';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  standalone: true,
  imports: [RouterLink, NgClass, NgIf, MenubarModule, TooltipModule, AsyncPipe]
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  layoutService = inject(LayoutService);
  authenticateClient = inject(AuthenticateClient);
  private authService = inject(AuthService);
  private tenantService = inject(TenantService);

  items$?: Observable<MenuItem[]>;
  public tenant$: Observable<Tenant | undefined> | null = null;
  private subscriptions = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  async ngOnInit(): Promise<void> {
    this.tenant$ = this.tenantService.getTenant();

    const tenantSubscription = this.tenant$.subscribe((tenant) => {
      if (!tenant?.name) return;
      posthog.group('company', tenant.dataKey, { name: tenant.name });
    });
    this.subscriptions.add(tenantSubscription);

    this.items$ = this.tenant$.pipe(
      combineLatestWith(this.authService.getPermissions()),
      map(([tenant, permissions]) => {
        return [
          {
            label: 'Assets',
            icon: 'pi pi-fw pi-list',
            routerLink: ['asset'],
            visible: permissions.includes('ReadWrite') && !!tenant
          },
          {
            label: 'Import',
            icon: 'pi pi-fw pi-file-import',
            visible: permissions.includes('ReadWrite') && !!tenant,
            items: [
              {
                label: 'Assets',
                routerLink: ['import-assets']
              },
              {
                label: 'Application requirements',
                routerLink: ['import-application-requirements']
              }
            ]
          },
          {
            label: 'Search',
            icon: 'pi pi-fw pi-search',
            routerLink: ['search'],
            visible: permissions.includes('ReadWrite') && !!tenant
          },
          {
            label: 'Configuration',
            icon: 'pi pi-fw pi-cog',
            visible: permissions.includes('MasterData') && !!tenant,
            items: [
              {
                label: 'Asset function types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['asset-function-type']
              },
              {
                label: 'Application requirement types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['application-requirement-type']
              },
              {
                label: 'Application implementation types',
                icon: 'pi pi-fw pi-database',
                routerLink: ['application-implementation-type']
              },
              {
                label: 'Computing platforms',
                icon: 'pi pi-fw pi-server',
                routerLink: ['computing-platform']
              }
            ]
          }
        ];
      })
    );
  }
}
