import { Component, OnInit, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { NgFor, NgIf } from '@angular/common';
import { AppMenuitemComponent } from './app.menuitem.component';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  standalone: true,
  imports: [NgFor, NgIf, AppMenuitemComponent]
})
export class AppMenuComponent implements OnInit {
  layoutService = inject(LayoutService);

  model: MenuItem[] = [];

  ngOnInit() {
    this.model = [];
  }
}
