<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar *ngIf="showHeader()"></app-topbar>
  <div
    [ngClass]="{ 'layout-main-no-header': !showHeader() }"
    class="layout-main-container"
  >
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="showHeader()"></app-footer>
  </div>
  <div class="layout-mask"></div>
</div>
