export class ImportAssetFileUpdatedMessage {
  errorMessage!: string | undefined;
  setId!: number;
  requestId!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init(_data?: any) {
    if (_data) {
      this.errorMessage = _data['errorMessage'];
      this.setId = _data['setId'];
      this.requestId = _data['requestId'];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJS(data: any): ImportAssetFileUpdatedMessage {
    data = typeof data === 'object' ? data : {};
    const result = new ImportAssetFileUpdatedMessage();
    result.init(data);
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['errorMessage'] = this.errorMessage;
    data['setId'] = this.setId;
    data['requestId'] = this.requestId;
    return data;
  }
}
