import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  AuthenticateResult,
  AuthenticationStatus
} from '../../clients/apiClients';
import { MessageService, PrimeTemplate } from 'primeng/api';
import posthog from 'posthog-js';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { TrimDirective } from '../../utils/trim-on-input.directive';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    PrimeTemplate,
    TrimDirective
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly fb = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly messageService = inject(MessageService);
  private redirectUrl?: string;
  private readonly subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public errorMessage?: string;
  public otpNeeded = false;
  loginForm = this.fb.nonNullable.group({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    otpCode: new FormControl('', {
      nonNullable: true,
      validators: [this.optValidator(() => this.otpNeeded)]
    })
  });

  forgotPasswordForm = this.fb.nonNullable.group({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    })
  });

  public forgotPassword = false;
  public isLoading = false;

  login() {
    this.errorMessage = undefined;

    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const otpCode = this.loginForm.value.otpCode;

    if (!email || !password) return;
    const loginObserver = {
      next: async (result: AuthenticateResult) => {
        if (result.status == AuthenticationStatus.OtpRequired) {
          this.otpNeeded = true;
          this.isLoading = false;
          posthog.capture('otp_required', { email: result.email });
        } else if (result.status == AuthenticationStatus.Success) {
          this.errorMessage = undefined;
          posthog.identify(result.userId, {
            email: result.email,
            name: result.name
          });

          await this.router.navigateByUrl(this.redirectUrl ?? '/');
          this.otpNeeded = false;
          this.isLoading = false;
        }
      },
      error: (error: string) => {
        posthog.capture('failed_login', { email: email });
        this.errorMessage = error;
        this.isLoading = false;
      }
    };

    this.isLoading = true;
    this.subscription.add(
      this.authService.login(email, password, otpCode).subscribe(loginObserver)
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        this.redirectUrl = params['returnUrl'];
      })
    );
  }

  optValidator(otpNeeded: () => boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!otpNeeded()) return null;

      if (
        !control.value ||
        control.value.length != 6 ||
        !/^\d+$/.test(control.value)
      )
        return { length: 'Code has to be 6 numbers' };

      return null;
    };
  }

  showForgotPassword() {
    this.forgotPassword = true;
  }

  sendForgotPassword() {
    const email = this.forgotPasswordForm.value.email;

    if (!email) throw Error('Email cannot be empty');

    this.subscription.add(
      this.authService.forgotPassword(email).subscribe(() => {
        this.forgotPassword = false;
        this.messageService.add({
          severity: 'success',
          detail: `If the user exists in the system, a recovery email is sent to ${email}`
        });
      })
    );
  }
}
