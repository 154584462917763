<p-card *ngIf="applicationRequirementSets; else loading">
  <ng-template pTemplate="header">
    <div class="flex w-full p-4 pb-0">
      <h3 class="flex w-6">Imported application requirement sets</h3>
      <div class="flex gap-1 w-6 justify-content-end">
        <button
          (click)="downloadTemplate()"
          class="flex p-button-outlined"
          label="Download template"
          pButton
        ></button>
        <p-fileUpload
          #fileUpload
          (uploadHandler)="uploadFiles($event)"
          class="flex"
          [auto]="true"
          [customUpload]="true"
          [maxFileSize]="50000000"
          [multiple]="true"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          chooseLabel="Import"
          mode="basic"
        ></p-fileUpload>
      </div>
    </div>
  </ng-template>
  <p-table [rowHover]="true" [value]="applicationRequirementSets">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Imported application requirements</th>
        <th scope="col"></th>
      </tr>
    </ng-template>
    <ng-template let-item pTemplate="body">
      <tr [ngClass]="{ 'linked-row': item.allRowsImported }">
        <td>
          {{ item.name }}
        </td>
        <td>
          {{ item.linkedRows + '/' + item.totalRows }}
        </td>
        <td class="text-right">
          <button
            (click)="openSet(item.id)"
            class="p-button p-button-sm p-button-outlined"
            label="Import"
            pButton
          ></button>
          <button
            (click)="removeSet(item.id)"
            class="p-button p-button-sm p-button-danger p-button-outlined ml-1"
            label="Remove"
            pButton
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <br />
  <div class="mt-3">
    <p-message
      *ngIf="errorMessage"
      [text]="errorMessage"
      class="flex-grow-1"
      severity="danger"
    ></p-message>
  </div>
</p-card>

<progress-toast></progress-toast>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<ng-template #loading> Loading...</ng-template>
