import { Injectable, inject } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FetchUser, ResetUser } from './app.actions';
import { AuthenticateClient, UserProfileResult } from '../clients/apiClients';
import { tap } from 'rxjs';
import posthog from 'posthog-js';

export class AppStateModel {
  profile?: UserProfileResult;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {}
})
@Injectable()
export class AppState {
  private authenticationClient = inject(AuthenticateClient);

  @Action(FetchUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchUser(ctx: StateContext<AppStateModel>, action: FetchUser) {
    return this.authenticationClient.getProfile().pipe(
      tap((profile) => {
        posthog.identify(profile.id, {
          email: profile.email,
          name: profile.name
        });
        ctx.patchState({ profile: profile });
      })
    );
  }

  @Action(ResetUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resetUser(ctx: StateContext<AppStateModel>, action: ResetUser) {
    ctx.patchState({ profile: undefined });
  }
}
