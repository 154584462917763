import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from '../shared/auth.service';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonDirective } from 'primeng/button';
import { Ripple } from 'primeng/ripple';
import { NgIf } from '@angular/common';
import { MessageModule } from 'primeng/message';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonDirective,
    Ripple,
    NgIf,
    MessageModule
  ]
})
export class ForgotPasswordComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private messageService = inject(MessageService);

  public errors?: string;

  registerForm = this.fb.group({
    key: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required]
      }
    )
  });

  constructor() {
    this.route.queryParamMap.subscribe((map) => {
      const email = map.get('email');
      const key = map.get('key');

      if (!email || !key) {
        this.messageService.add({
          severity: 'error',
          detail: 'The invite is invalid'
        });
        return;
      }

      this.registerForm.patchValue({ email: email, key: key });
    });
  }

  changePassword() {
    const val = this.registerForm.getRawValue();
    if (!val.key || !val.email || !val.password) {
      return;
    }

    const createUserObserver = {
      next: async () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Password reset successfully'
        });
        await this.router.navigate(['login']);
      },
      error: (error: string[]) => {
        this.errors = error.join('<br /><br />');
      }
    };

    this.authService
      .resetPassword(val.key, val.email, val.password)
      .subscribe(createUserObserver);
  }
}
