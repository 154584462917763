import { Injectable, inject } from '@angular/core';
import { SignalrService } from '../app/shared/signalr.service';
import { Store } from '@ngxs/store';
import {
  FetchApplicationRequirementTypes,
  FetchFunctionTypes,
  ResetApplicationRequirementTypes,
  ResetFunctionTypes
} from './master-data.actions';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  private signalRService = inject(SignalrService);
  private store = inject(Store);

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      this.signalRService
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .register<any>('MasterDataUpdated')
        .subscribe((data) => {
          if (data == 'ApplicationRequirementTypesUpdated') {
            this.store.dispatch(new ResetApplicationRequirementTypes());
            this.store.dispatch(new FetchApplicationRequirementTypes());
          } else if (data == 'AssetFunctionTypesUpdated') {
            this.store.dispatch(new ResetFunctionTypes());
            this.store.dispatch(new FetchFunctionTypes());
          }
        });
      resolve();
    });
  }
}
