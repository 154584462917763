import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { SetTreeMode } from '../../../shared/asset.actions';
import { TreeMode } from '../../../shared/treeMode';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {
  ConfirmationService,
  MessageService,
  PrimeTemplate
} from 'primeng/api';
import {
  FileResponse,
  ImportedApplicationRequirementSetListResult,
  TemplateClient,
  TemplateType
} from '../../../clients/apiClients';
import { ImportApplicationRequirementsService } from './import-application-requirements.service';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { SignalrService } from 'src/app/shared/signalr.service';
import { ProgressService } from '../../shared/progress-toast/progress.service';
import { ImportApplicationRequirementFileUpdatedMessage } from './import-application-requirement-file-updated-message';
import { NgIf, NgClass } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ButtonDirective } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { ProgressToastComponent } from '../../shared/progress-toast/progress-toast.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-import-application-requirements',
  templateUrl: './import-application-requirements.component.html',
  styleUrls: ['./import-application-requirements.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CardModule,
    PrimeTemplate,
    ButtonDirective,
    FileUploadModule,
    TableModule,
    NgClass,
    MessageModule,
    ProgressToastComponent,
    ConfirmDialogModule
  ]
})
export class ImportApplicationRequirementsComponent
  implements OnInit, OnDestroy
{
  private store = inject(Store);
  private router = inject(Router);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  private importApplicationRequirementsService = inject(
    ImportApplicationRequirementsService
  );
  private templateClient = inject(TemplateClient);
  private signalrService = inject(SignalrService);
  private progressService = inject(ProgressService);

  errorMessage?: string;
  public applicationRequirementSets?: ImportedApplicationRequirementSetListResult[];

  private activeRequests: string[] = [];

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  constructor() {
    this.signalrService
      .register<string>('ImportApplicationRequirementFileUpdated')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe(async (data: any) => {
        await this.importFileReady(
          ImportApplicationRequirementFileUpdatedMessage.fromJS(data)
        ); // NOSONAR
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetTreeMode(TreeMode.Navigation));
  }

  ngOnInit(): void {
    this.store.dispatch(new SetTreeMode(TreeMode.Picker));

    this.importApplicationRequirementsService
      .getImportedSets()
      .subscribe((sets) => {
        this.applicationRequirementSets = sets;
      });
  }

  async uploadFiles($event: { files: File[] }) {
    this.progressService.add('Importing file, please wait...');

    try {
      const requestId =
        await this.importApplicationRequirementsService.importFile(
          $event.files[0]
        );
      this.activeRequests.push(requestId);
    } catch {
      this.progressService.complete({ title: 'empty' });
      this.messageService.add({
        severity: 'error',
        detail: `Error importing the file. Please try again or contact the support email.`
      });
    }

    this.fileUpload.clear();
  }

  async importFileReady(
    result: ImportApplicationRequirementFileUpdatedMessage
  ) {
    const setId = result.setId;
    const requestId = result.requestId;
    const errorMessage = result.errorMessage;

    if (!this.activeRequests.includes(requestId)) {
      return;
    }

    this.activeRequests = this.activeRequests.filter((o) => o !== requestId);
    this.progressService.complete({ title: 'empty' });

    if (errorMessage) {
      this.messageService.add({
        severity: 'error',
        detail: errorMessage
      });
      return;
    }

    this.openSet(setId);
    this.messageService.add({
      severity: 'success',
      detail: `Application requirement set uploaded successfully`
    });
  }

  async openSet(id: number) {
    await this.router.navigate(['asset', 'import-requirements', id]);
  }

  removeSet(id: number) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to remove the imported set?`,
      accept: async () => {
        this.importApplicationRequirementsService
          .removeSet(id)
          .subscribe(() => {
            if (!this.applicationRequirementSets) return;
            this.applicationRequirementSets =
              this.applicationRequirementSets.filter((o) => o.id != id);
            this.messageService.add({
              severity: 'success',
              detail: `Application requirement set removed successfully`
            });
          });
      }
    });
  }

  downloadTemplate() {
    this.templateClient
      .getTemplate(undefined, TemplateType.ApplicationRequirements)
      .subscribe((file: FileResponse) => {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', file.fileName ?? 'file');
        const href = URL.createObjectURL(file.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      });
  }
}
