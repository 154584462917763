<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img src="assets/layout/images/logo.svg" alt="Cybersort" />
  </a>

  <button
    class="p-link layout-topbar-menu-button layout-topbar-button p-trigger"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    class="layout-topbar-menu"
    [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }"
  >
    <div class="flex align-items-center justify-content-end mr-4">
      {{ (tenant$ | async)?.name }}
    </div>
    <p-menubar *ngIf="items$ | async as items" [model]="items"></p-menubar>

    <div class="flex align-items-center">
      <button
        [routerLink]="['settings', 'profile']"
        class="flex p-link layout-topbar-button"
        pTooltip="Settings"
      >
        <i class="pi pi-cog"></i>
      </button>
      <button
        (click)="logout()"
        pTooltip="Logout"
        class="flex p-link layout-topbar-button"
      >
        <i class="pi pi-sign-out"></i>
      </button>
    </div>
  </div>
</div>
