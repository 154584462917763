<p-card *ngIf="assetSets; else loading">
  <ng-template pTemplate="header">
    <div class="flex w-full p-4 pb-0">
      <h3 class="flex w-6">Imported asset sets</h3>
      <div class="flex gap-1 w-6 justify-content-end">
        <button
          (click)="showDialog(true)"
          class="p-button-outlined"
          label="Download template"
          pButton
        ></button>
        <button
          (click)="showDialog(false)"
          class="p-button-filled"
          label="Import"
          pButton
        ></button>
      </div>
    </div>
  </ng-template>
  <p-table [rowHover]="true" [value]="assetSets">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Imported assets</th>
        <th scope="col"></th>
      </tr>
    </ng-template>
    <ng-template let-item pTemplate="body">
      <tr [ngClass]="{ 'linked-row': item.allRowsImported }">
        <td>
          {{ item.name }}
        </td>
        <td>
          {{ item.linkedRows + '/' + item.totalRows }}
        </td>
        <td class="text-right">
          <button
            (click)="openAssetSet(item.id)"
            class="p-button p-button-sm p-button-outlined"
            label="Import"
            pButton
          ></button>
          <button
            (click)="removeSet(item.id)"
            class="p-button p-button-sm p-button-danger p-button-outlined ml-1"
            label="Remove"
            pButton
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="mt-3">
    <p-message
      *ngIf="errorMessage"
      [text]="errorMessage"
      class="flex-grow-1"
      severity="danger"
    ></p-message>
  </div>
</p-card>

<progress-toast></progress-toast>

<p-confirmDialog
  key="remove-asset-confirm"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-dialog [(visible)]="dialogVisible" [closable]="true" [modal]="true">
  <ng-template pTemplate="header">
    <h3 *ngIf="isTemplate">Download asset template</h3>
    <h3 *ngIf="!isTemplate">Import asset file</h3>
  </ng-template>
  <ng-container>
    <div class="flex gap-1">
      <p-dropdown
        #functionTypeSelector
        data-test="function-type-selector"
        [options]="functionTypes"
        styleClass="w-20rem"
        optionLabel="name"
        placeholder="Select asset function type"
        (onChange)="functionTypeSelectionChanged($event)"
        appendTo="body"
      ></p-dropdown>
      <button
        *ngIf="isTemplate"
        (click)="downloadTemplate()"
        class="p-button-outlined"
        label="Download template"
        pButton
      ></button>
      <p-fileUpload
        *ngIf="!isTemplate"
        #fileUpload
        (uploadHandler)="uploadFiles($event)"
        [auto]="true"
        [customUpload]="true"
        [maxFileSize]="50000000"
        [multiple]="true"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        chooseLabel="Choose file"
        mode="basic"
      ></p-fileUpload>
    </div> </ng-container
></p-dialog>

<ng-template #loading> Loading...</ng-template>
